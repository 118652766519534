import { inject, Injectable } from '@angular/core';
import { IStepOption, TourService } from 'ngx-ui-tour-md-menu';
import { HeaderSearchService } from '../header-search/header-search.service';
// import { HeaderSearchService } from './header-search/header-search.service';

@Injectable({
  providedIn: 'root',
})
export class CommonTourService {
  readonly tourSteps: IStepOption[] = [
    {
      anchorId: 'multiple-dashboard',
      content: 'translation.helptour.text22',
      route: 'cdm-metrics/my-dashboard',
      showProgress: true,
    },

    {
      anchorId: 'contrastdashboard',
      content: ' Contrast dashboard',
    },
    {
      anchorId: 'radiology',
      content: 'translation.helptour.text23',
      route: 'cdm-metrics/my-dashboard',
    },

    // {
    //   anchorId: 'indicatorperformance',
    //   content:
    //     'color-coded performance indication based upon changes in trend and comparision to benchmark',
    //   route: '',
    // },
    // {
    //   anchorId: 'kpi-indicator',
    //   content:
    //     'translation.helptour.text16',
    //   route: '',
    // },
    // {
    //   anchorId: 'benchmark-indicator',
    //   content:
    //     'translation.helptour.text17',
    //   route: '',
    // },
    // {
    //   anchorId: 'observation-indicator',
    //   content:
    //     'translation.helptour.text18',
    //   route: '',
    // },
    // {
    //   anchorId: 'graph',
    //   content:
    //     'translation.helptour.text20',
    //   route: '',
    // },
    // {
    //   anchorId: 'reports',
    //   content: 'translation.helptour.text33',
    //   route: 'report/standardreport',
    // },
  ];

  public readonly tourService = inject(TourService);

  constructor(private headerservice: HeaderSearchService) {
    this.tourService.initialize(this.tourSteps, {
      enableBackdrop: true,
      backdropConfig: {
        offset: 10,
      },
      showProgress: true,
      disablePageScrolling: true,
    });
  }

  startTour() {
    this.headerservice.updateHospitalLocation(
      '121115,125372,4595511',
      '121115 - SALEM COMMUNITY HOSPITAL, 125372 - Salem Community Hospital, 4595511 - St Vincent Public Hospital',
      '/cdm-metrics/my-dashboard'
    );
    this.tourService.start();
  }
}
